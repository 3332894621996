








import { defineComponent } from "@vue/composition-api";

import ProjectPageWrapper from "@/app/components/Project/ProjectPageWrapper.vue";
import TaskView from "@/app/components/Task/TaskView.vue";

export default defineComponent({
  name: "ProjectTasksPage",
  components: {
    TaskView,
    ProjectPageWrapper,
  },
});
